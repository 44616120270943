import Brian from '../../assets/images/social/Brian.jpg'
import Cory from '../../assets/images/social/Cory.jpg'
import Mike from '../../assets/images/social/Mike.jpg'
import Owen from '../../assets/images/social/Owen.jpg'
import Regina from '../../assets/images/social/Regina.jpg'
import Sal from '../../assets/images/social/Sal.jpg'
import Vince from '../../assets/images/social/Vince.jpg'
import Graham from '../../assets/images/social/Graham.jpg'
import Kenny from '../../assets/images/social/Kenny.jpg'

export const SOCIAL = {
  WHAT: [
    {
      image: Cory,
      content: "I hope I don’t ever have to use it, but I feel better knowing it’s there.",
      handle: "Cory P.",
    },
    {
      image: Graham,
      content: "The latest addition to my EDC setup.",
      handle: "Graham M.",
    },
    {
      image: Sal,
      content: "My monthly shipment of TacStorm ammo.",
      handle: "Sal F.",
    },
    {
      image: Kenny,
      content: "Bullseye!",
      handle: "Kenny U.",
    },
    {
      image: Regina,
      content: "Can’t wait til the precinct gets some of these.",
      handle: "Regina P.",
    },
    {
      image: Vince,
      content: "Help! I’m running out of things to shoot!",
      handle: "Vince M.",
    },
    {
      image: Mike,
      content: "Bang! Bang! Bang! Bang! Bang! Bang!",
      handle: "Mike T.",
    },
    {
      image: Owen,
      content: "Finally, a weapon worthy of my God-given right to bear arms!",
      handle: "Owen G.",
    },
    {
      image: Brian,
      content: "I don’t own a TacStorm. I own a family member.",
      handle: "Brian Y.",
    },
  ]
}
