import Cx from 'classNames'
import React from 'react'
import { render } from 'react-dom'
import styles from './styles'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import ImageGallery from '../../components/ImageGallery'
import Footer from '../../components/Footer'
import PageHead from '/components/PageHead'
import { COLORS, URLS } from '/constants'

class CustomerService extends React.Component {
  render() {
    return <div className={styles.train}>
      <PageHead url={URLS.CUSTOMER_SERVICE} />
      <Grid>
        <SectionTitle>CUSTOMER SERVICE</SectionTitle>
        <div className={styles.categories}>
          <div className={styles.levels} style={{flex:1}}>
            <p className="h2" style={{color: COLORS.GREEN}}>CONTACT US</p><br/>
            <p>TacStorm is designed to provide a constant stream of protective firepower right out of the box. In the rare event you do encounter a problem, our team of native-English-speaking service professionals is here to help via email.</p>
            <a href="mailto:support@tacstorm.com"><p>support@tacstorm.com</p></a>
            <br/><br/>
            <p className="h2" style={{color: COLORS.GREEN}}>WARRANTY</p><br/>
            <p>All Talonpoint products are proudly 100% warranty free.</p>
          </div>
          <div className={styles.levels} style={{flex:1}}>
            <p className="h2" style={{color: COLORS.GREEN}}>RETURN POLICY</p><br/>
            <p>In order to qualify for a return, the product must meet the following criteria:</p>
            <ul>
              <li>In the possession of its current owner</li>
              <li>Fired fewer than 15,000,000 rounds</li>
              <li>Never taken into evidence by any law enforcement agency</li>
            </ul>
            <br/>
            <p className="h2" style={{color: COLORS.GREEN}}>SHIPPING INSTRUCTIONS</p><br/>
            <p>Before sending the product back, please follow these steps to ensure a smooth trip:</p>
            <ul>
              <li>Select a titanium-reinforced box</li>
              <li>Surround the firearm in ballistic packing peanuts or similar material</li>
              <li>Purchase shipping insurance specific to constantly firing weapons (rates vary)</li>
              <li>Have a frank discussion with your mail carrier about what you’re asking them to do</li>
            </ul>
          </div>
        </div>
      </Grid>

      <Grid className={styles.pattern}>
        <SectionTitle>MEDIA IMAGES</SectionTitle>
        <ImageGallery className={styles.imgs} />
      </Grid>

      <Footer />
    </div>
  }
}

export default CustomerService
