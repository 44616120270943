import React from 'react'
import { withRouter } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'

class ScrollSpy extends React.Component {
  onEnter = () => {
    this.props.history.replace(this.props.id, { noscroll: true })
  }

  onLeave = () => {}

  render() {
    let { children, id, className, style, ...rest} = this.props
    return (
      <Waypoint
        onEnter={this.onEnter}
        onLeave={this.onLeave}
        topOffset={window.innerHeight * 0.4}
        bottomOffset={window.innerHeight * 0.5}>
        <div id={id} className={className} style={style}>
          {children}
        </div>
      </Waypoint>
    )
  }
}

export default withRouter(ScrollSpy)