require('es6-object-assign').polyfill()
require('es6-promise').polyfill()
import 'whatwg-fetch'

// Global Style
import 'ress/ress.css'
import './global.css'


import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Root from './views/Root'

function init() {
  render(
    <BrowserRouter>
      <Root />
    </BrowserRouter>,
    document.getElementById('root'))
}

init()