import Cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './styles'
import Modal from '/components/Modal'
import { getContainDimensions } from '../../helpers'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'


class VideoPlayer extends React.Component {
    static defaultProps = {
      videoId: 'hFlo-B-l4Ds',
      aspect: 16 / 9,
    }

    state = {
      width: 240,
      height: 135,
    }

    onResize = () => {
      let rect = this._content.getBoundingClientRect()
      let padding = 50
      let dimens = getContainDimensions(
        this.props.aspect,
        rect.width - 2 * padding,
        rect.height - 2 * padding
      )
      this.setState({
        width: dimens.width,
        height: dimens.height,
      })
    }

    componentDidMount() {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
    }

    onCloseClick = () => {
      this.props.history.goBack()
    }


    render() {
      return <Modal>
        <PageHead url={URLS.DEMO} />
        <div className={styles.videodiv}
          onClick={this.onClick}
          ref={c=>(this._content=c)}
        >
          <iframe
            width={this.state.width}
            height={this.state.height}
            src="https://www.youtube.com/embed/5VkRPXN2KhM"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <button className={styles.close} onClick={this.onCloseClick}>
          <svg viewBox="0 0 10 10">
            <line x1="1" y1="1" x2="9" y2="9" />
            <line x1="9" y1="1" x2="1" y2="9" />
          </svg>
        </button>
      </Modal>
    }
}

export default withRouter(VideoPlayer)
