import React from 'react'
import Cx from 'classnames'

export default ({className}) => {
  return (
    <svg className={className} viewBox="0 0 576 576">
      <path d="M554,129.47,507.72,338.39H155.5c3.79,17.07,7.42,33.44,11.17,50.34h329.8l-5.35,24.08h-344S80.18,106.05,76.71,88.86L22.05,76.24l.06-24L97,68.51s11.3,54.74,12.56,60.7Z"/>
      <path d="M276.5,481.39a42.57,42.57,0,0,1-43.08,42.34c-23.55-.36-42.75-19.92-42.21-43.42.58-25.05,22.19-43,44.06-42.12C258.2,439.16,276.82,458.13,276.5,481.39Z"/>
      <path d="M406.37,438.29c23.47,0,43,19.47,42.87,42.75-.16,23.59-19.55,42.86-43,42.72s-42.89-19.76-42.62-43.1A43,43,0,0,1,406.37,438.29Z"/>
    </svg>
  )
}