import rainofpain from '../../assets/images/buy_logos/rainofpain.png'
import firepower from '../../assets/images/buy_logos/firepower.png'
import camokang from '../../assets/images/buy_logos/camokang.png'
import unclemortar from '../../assets/images/buy_logos/unclemortar2.png'

export const PARTNERS = {
  WHAT: [
    {
      icon: rainofpain,
    },
    {
      icon: firepower,
    },
    {
      icon: camokang,
    },
    {
      icon: unclemortar,
    },
  ]
}
