import React from 'react'
import { Link } from 'react-router-dom'
import logo_tacstorm from '../../assets/images/tacstorm_logo.png'
import styles from './styles'
import { URLS } from '/constants'

export default props => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerlayout}>
        <div className={styles.footerlinks}>
          <div><Link to={URLS.TRAINING}>Training</Link></div>
          <div><Link to={URLS.CUSTOMER_SERVICE}>Customer Service</Link></div>
        </div>
        <div className={styles.center}>
          <Link to={URLS.HOME}>
            <img className={styles.tacstorm} src={logo_tacstorm} />
          </Link>
        </div>
        <div className={styles.social}>
          <div>
            <div>
              <a href="https://www.twitter.com/tacstormusa" target="_blank" className="fab fa-twitter" />
              <a href="https://www.instagram.com/tacstorm/" target="_blank" className="fab fa-instagram" />
            </div>
          </div>
        </div>
      </div>
      <div className="small center">©{new Date().getFullYear()} Thud, Inc.<br/>DISCLAIMER: TacStorm is not a real gun. This is a parody website.
        <Link to={URLS.PRIVACYPOLICY}><br/>Full Disclaimer | Privacy Policy </Link></div>
    </div>
  )
}
