import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '../../components/TextColumn'
import Footer from '../../components/Footer'
import { BIOS } from './data.js'
import Dvd from '../../assets/images/training/DVD_Layout.png'
import DwayneFB from '../../assets/images/training/Dwayne_FullBody.jpg'
import PageHead from '/components/PageHead'
import { COLORS, URLS } from '/constants'

class Training extends React.Component {
  render() {
    return <div className={styles.train}>
      <PageHead url={URLS.TRAINING} />
      <Grid className={styles.texture3}>
        <SectionTitle>"Luke &rsquo;n Loaded" DVD TRAINING SERIES</SectionTitle>
        <TextColumn>
          <p>Hone your continuous shooting skills or learn some new ones with Team Talonpoint Pro Dwayne Luke as your one-on-one teacher. Over the course of four information-packed DVD discs, you will cover the following concepts:</p>
        </TextColumn>
        <div className={styles.dvdseries}>
          <div style={{flex:1.5}}>
            <img className={styles.dvd} src={Dvd}/>
          </div>
          <div className={styles.levels} style={{flex:1}}>
            <p className="h2" style={{color: COLORS.GREEN}}>LEVEL I</p>
            <ul>
              <li>Proper continuous shooting stance</li>
              <li>Cleaning and maintenance</li>
              <li>Personal safety vs. uninterrupted sleep</li>
              <li>Open carry gray areas</li>
              <li>Mass ammunition storage</li>
            </ul>
            <p className="h2" style={{color: COLORS.GREEN}}>LEVEL II</p>
            <ul>
              <li>Managing chronic shoulder, hand, and ear pain</li>
              <li>Camouflage for every body shape</li>
              <li>Ricochet-proofing your home</li>
              <li>Establishing work/life/gun balance</li>
              <li>TacStorm and your marriages</li>
            </ul>
            <p className="h2" style={{color: COLORS.GREEN}}>LEVEL III</p>
            <ul>
              <li>Protecting your gun from children</li>
              <li>Reducing "oops" moments</li>
              <li>Juggling</li>
              <li>Traditional bullet casing art</li>
              <li>Best meats for DIY target practice</li>
            </ul>
            <p className="h2" style={{color: COLORS.GREEN}}>LEVEL IV</p>
            <ul>
              <li>TacStorm for the workplace</li>
              <li>Humane pet euthanasia</li>
              <li>So you've been pulled over...</li>
              <li>Bad guy identification (Beginner/Intermediate/Advanced)</li>
              <li>Putting it all together</li>
            </ul>
          </div>
        </div>
      </Grid>

      {/* Team Talonpoint */}
      <Grid className={Cx('shadow')}>
        <SectionTitle>TEAM TALONPOINT</SectionTitle>
        <TextColumn>
          <p>Team Talonpoint is composed of top- and amateur-tier competitive shooters who represent the company’s brands at gun shows, trade expos, birthday parties, and corporate barbecues around the United States and Guam.</p>
        </TextColumn>
        <div className={styles.dwayneluke}>
          <div style={{flex:1}}>
            <img className={styles.dwayneimage} src={DwayneFB}/>
          </div>
          <div className={styles.dwaynebio} style={{flex:1.5}}>
            <p className="h2 center">DWAYNE LUKE</p>
            <br/>
            <p>An active member of the shooting community since his teenage years, Dwayne Luke enlisted in the U.S. Coast Guard at age 21. After distinguishing himself in basic training as a gifted winch crank, he was selected to join a Special Tug Operator unit that deployed to Cape Cod at the height of the 2003 summer holiday season. There he served with distinction, leading several midnight sandbar extractions of vessels whose owners put up fierce resistance at the suggestion they were intoxicated. On his second deployment to the Hudson River in 2005, he sustained a broken clavicle while escorting the Staten Island Ferry to dry dock, effectively ending his military career.</p>
            <br/>
            <p>Following a medical discharge, Luke returned to his native Anaconda, Montana, where he became a door-to-door firearms instructor and a ballistics consultant for the Montana Lottery. He reentered the world of competitive shooting in 2013, finishing second and seventh, respectively, at the Bush’s Grillin’ Beans and Heinz Baked Beans Invitationals. He landed on Talonpoint’s radar the following year after winning the NRA’s prestigious Truck Decal Placement Of The Year award, officially becoming a Team Talonpoint Pro in 2015.</p>
            <br/>
            <p>In his current capacity, he represents the company as a TacStorm brand ambassador. When he’s not traveling the country educating gun owners on safety and technique, he lives in San Diego with his partners Lauren and Shelley and his Labrador retriever, Rayne.</p>
          </div>
        </div>
        <div className={styles.bios}>{ BIOS.WHAT.map((o, i) => {
          return (
            <div className="type" key={i}>
              <div className="center">
                <img src={o.image} />
              </div>
              <div className="h2 center">{o.name}</div>
              <div className={styles.biotype}>{o.text}</div>
            </div>
          )
        })}</div>
      </Grid>

      <Footer />
    </div>
  }
}

export default Training
