export const URLS = {
  HOME: '/',
  PERMASHOT: '/permashot-tech',
  SPECS: '/specs',
  REVIEWS: '/reviews',
  BUY: '/where-to-buy',
  TRAINING: '/training',
  CUSTOMER_SERVICE: '/customerservice',
  CART: '/cart',
  DEMO: '/demo',
  PRIVACYPOLICY: '/privacypolicy',
}

export const SITE_TITLE = 'Tacstorm'

function titleify(str) {
  return `${str} - ${SITE_TITLE}`
}

export const PAGE_TITLES = {
  [URLS.HOME]: SITE_TITLE,
  [URLS.CART]: titleify('Cart'),
  [URLS.CUSTOMER_SERVICE]: titleify('Customer Service'),
  [URLS.DEMO]: titleify('Demo'),
  [URLS.TRAINING]: titleify('Training'),
  [URLS.PRIVACYPOLICY]: titleify('Privacy Policy'),
}

export const COLORS = {
  GREEN: 'hsl(63, 91%, 40%)',
}
