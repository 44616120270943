import Client from 'shopify-buy'
import nanobus from 'nanobus'
export const cartEvents = nanobus()

const API_KEY = '7f13098445b4739ef2890601edd6130f'
const MAIN_COLLECTION = 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzExMzQ3NzI1NTIzMg=='
const CACHE = {
  checkoutId: localStorage.getItem('checkoutId'),
  checkout: false,
}

const client = Client.buildClient({
  domain: 'tacstorm.myshopify.com',
  storefrontAccessToken: API_KEY
})
fetchCheckout()

export default client

function updateCachedCheckout(checkout) {
  CACHE.checkout = checkout
  cartEvents.emit('checkout.update')
}

// FETCH PRODUCTS
export function fetchProducts() {
  // Check cache
  if (CACHE.products) {
    return Promise.resolve(CACHE.products)
  }
  // Fetch products
  return client.collection.fetchWithProducts(MAIN_COLLECTION).then(collection => {
    let products = collection.products
    CACHE.products = products
    return products
  })
}

// CHECKOUT
let fetchingCheckout = false
export function fetchCheckout() {
  if (CACHE.checkout) {
    return Promise.resolve(CACHE.checkout)
  }
  else if (CACHE.checkoutId) {
    // Fetch an existing one
    if (fetchingCheckout !== false) return fetchingCheckout
    fetchingCheckout = client.checkout.fetch(CACHE.checkoutId).then((checkout) => {
      // Reset fetching state
      fetchingCheckout = false
      // User already checked out, create a new checkout
      if (checkout.completedAt !== null) {
        return createCheckout()
      }
      updateCachedCheckout(checkout)
      return checkout
    })
    return fetchingCheckout
  }

  // Create a new one
  return createCheckout()
}

function createCheckout() {
  if (fetchingCheckout !== false) return fetchingCheckout
  fetchingCheckout = client.checkout.create().then((checkout) => {
    localStorage.setItem('checkoutId', checkout.id)
    CACHE.checkoutId = checkout.id
    updateCachedCheckout(checkout)
    fetchingCheckout = false
    return checkout
  })
  return fetchingCheckout
}

export function addToCheckout(variant, quantity) {
  let lineItems = [{
    variantId: variant.id,
    quantity
  }]
  return fetchCheckout().then(checkout => {
    return client.checkout.addLineItems(checkout.id, lineItems).then((checkout) => {
      updateCachedCheckout(checkout)
      return checkout
    })
  })
}

export function updateCheckout(lineItemId, quantity) {
  let lineItems = [{
    id: lineItemId,
    quantity
  }]
  return fetchCheckout().then(checkout => {
    return client.checkout.updateLineItems(checkout.id, lineItems).then((checkout) => {
      updateCachedCheckout(checkout)
      return checkout
    })
  })
}
