import Cx from 'classnames'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
import styles from './styles'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import Testimonials from '/components/Testimonials'
import ScrollSpy from '/components/ScrollSpy'
import Specs from '/components/Specs'
import Footer from '/components/Footer'
import { PARTNERS } from './data'
import Gear from './Gear'
import { URLS } from '/constants'
import Hero from './Hero'
import Intro from './Intro'
import Permashot from './Permashot'
import PageHead from '/components/PageHead'


class TacStorm extends React.Component {
  render() {
    window.h = this.props.history
    let delay = (window.innerWidth < 600) ? 0 : 150

    return <div className={styles.container}>
      <PageHead url={URLS.HOME} />

      <ScrollSpy id={URLS.HOME}>
        <Hero />
        <Intro />
      </ScrollSpy>

      {/* What Can You Learn */}
      <ScrollSpy id={URLS.PERMASHOT}>
        <Permashot />
      </ScrollSpy>

      {/* Specs */}
      <ScrollSpy id={URLS.SPECS}>
        <Grid className="shadow">
          <SectionTitle>SPECS</SectionTitle>
          <TextColumn>
            <p>Compact yet deadly, TacStorm gives you the upper hand by laying down continuous fire before, during, and long after engaging a threat.</p>
          </TextColumn>
          <Specs />
        </Grid>
      </ScrollSpy>

      {/* Training */}
      <div className={Cx(styles.getstarted)} id={URLS.TRAINING}>
        <a href={URLS.TRAINING}>
          <span className="h2 center">Get trained with Team Talonpoint!</span>
        </a>
      </div>

      {/* Testimonials */}
      <ScrollSpy id={URLS.REVIEWS}>
        <Grid className={styles.carbonfiber}>
          <SectionTitle>REVIEWS</SectionTitle>
          <Testimonials />
        </Grid>
      </ScrollSpy>

      {/* Get Started */}
      <ScrollSpy id={URLS.BUY}>
        <Grid className={styles.texture}>
          <SectionTitle>WHERE TO BUY</SectionTitle>
          <TextColumn>
            <p>Find TacStorm at one of our licensed retail partners:</p>
          </TextColumn>
          <div className={styles.partners}>{ PARTNERS.WHAT.map((o, i) => {
            return (
              <ScrollAnimation animateIn="pulse" delay={delay*i} duration={.25} offset={200} key={i}>
                <div className={styles.tech} key={i}>
                  <img className={styles.buyicon} src={o.icon} />
                </div>
              </ScrollAnimation>
            )
          })}</div>
        </Grid>
      </ScrollSpy>

      {/*Footer*/}
      <Footer />
    </div>
  }
}

export default TacStorm
