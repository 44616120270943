import Cx from 'classNames'
import React from 'react'
import { render } from 'react-dom'
import styles from './styles'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import Footer from '../../components/Footer'
import PageHead from '/components/PageHead'
import { URLS } from '/constants'

class PrivacyPolicy extends React.Component {
  render() {
    return <div>
      <PageHead url={URLS.PRIVACYPOLICY} />
      <Grid className={styles.pp}>
        <SectionTitle>FULL DISCLAIMER</SectionTitle>
        <div className={styles.categories}>
          <p>TacStorm is not a real gun. This is a parody website created by <a href="http://thud.com" target="_blank">Thud</a>, a company that produces works of satire. All names onsite are invented and any connection to real persons (living or dead) is coincidental, and all images are models from stock photos. Nothing said here should be taken seriously or as an assertion of fact about (or endorsement by) any person or business—it is meant to be a joke, hyperbole, parody, satire, or opinion (and sometimes all of the above) for users 18 and older. Again, TacStorm is not real, nor are any of its retail partners, and any similarities to actual products or organizations are unintentional and kind of terrifying.</p>
        </div>
        <br/>
        <SectionTitle>PRIVACY POLICY</SectionTitle>
        <div className={styles.categories}>
          <p>Thud values your personal privacy and protecting your information is our priority. This Privacy Policy applies to all of the sites created and owned by Thud, Inc. (“Thud,” “we,” or “us”). This statement of privacy includes TacStorm and Thud, Inc. and governs data collection and usage. Thud, Inc. is a comedy and satire company. By using this Thud, Inc. website, TacStorm.com, you consent to the data practices described in this statement.</p>
          <p><b>Collection of your Personal Information</b></p>
          <p>Thud, Inc. may collect personally identifiable information from our users at several different points, such as your:</p>
          <ul>
            <li>First and Last Name</li>
            <li>Mailing Address</li>
            <li>Email Address</li>
            <li>Phone Number</li>
          </ul>
          <p>If you purchase Thud, Inc.’s products and services, we may collect billing and credit card information. This information is used to complete the purchase transaction.</p>
          <p>Thud may also collect anonymous demographic information, which is not unique to you, such as your:</p>
          <ul>
            <li>Age</li>
            <li>Gender</li>
            <li>Interests</li>
          </ul>
          <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on a Thud, Inc. website. These may include: (a) registering an email or signing up for a mailing list on a Thud, Inc. website; (b) sending us an email message; (c) submitting your credit card or other payment information when ordering and purchasing products and services on a Thud, Inc. website. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
          <p><b>Use of your Personal Information</b></p>
          <p>Thud, Inc. collects and uses your personal information to operate its website(s) and deliver the services you have requested. </p>
          <p>Thud, Inc. may also use your personally identifiable information to inform you of other products and services available from our company.</p>
          <p><b>Sharing Information with Third Parties</b></p>
          <p>Thud, Inc. does not sell, rent or lease its customer lists to third parties.</p>
          <p>Thud may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Thud, Inc., and they are required to maintain the confidentiality of your information.</p>
          <p>Thud, Inc. may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Thud, Inc. or one of its websites; (b) protect and defend the rights or property of Thud, Inc.; and/or (c) act under exigent circumstances to protect the personal safety of users of Thud, Inc., or the public.</p>
          <p><b>Tracking User Behavior</b></p>
          <p>Thud, Inc. may keep track of the websites and pages our users visit within a session on any Thud, Inc. website, in order to determine what Thud services are the most popular.</p>
          <p>Thud, Inc. does not monitor or take action with respect to web browser “Do Not Track” signals or other similar mechanisms at this time.</p>
          <p><b>Automatically Collected Information</b></p>
          <p>Information about your computer hardware and software may be automatically collected by Thud, Inc. This information can include: your IP address, browser type, domain names, access times, and referring website addresses. This information is used for the operation of our services, to maintain quality of our services, and to provide general statistics regarding use of Thud, Inc. websites.</p>
          <p><b>Use of Cookies</b></p>
          <p>Thud, Inc. websites may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
          <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the web server that you have returned to a specific page. For example, if you personalize Thud, Inc. pages, or register with Thud, Inc. sites or services, a cookie helps the company to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Thud, Inc. website, the information you previously provided can be retrieved, so you can easily use the features that you customized.</p>
          <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Thud, Inc. services or websites you visit. </p>
          <p><b>Security of your Personal Information</b></p>
          <p>Thud, Inc. secures your personal information from unauthorized access, use, or disclosure. Thud, Inc. uses the following method for this purpose: SSL Protocol.</p>
          <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
          <p>Thud, Inc. strives to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the internet which are beyond Thud, Inc.’s control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through Thud, Inc. websites cannot be guaranteed.</p>
          <p><b>Children Under 13</b></p>
          <p>Thud, Inc. does not knowingly collect personally identifiable information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission to use Thud, Inc. websites. All Thud, Inc. websites are intended for readers over 18 years of age.</p>
          <p><b>Email Communications</b></p>
          <p>From time to time, Thud, Inc. may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our services, we may receive a notification when you open an email from Thud, Inc. or click on a link therein.</p>
          <p>If you would like to stop receiving marketing or promotional communications via email from Thud, Inc., you may opt out of such communications by clicking on the “Unsubscribe” button. Such a button will be located at the bottom of all marketing or promotional emails sent by Thud, Inc.</p>
          <p><b>External Data Storage Sites</b></p>
          <p>Thud, Inc. may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
          <p><b>Changes to this Statement</b></p>
          <p>Thud, Inc. reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our websites, and/or by updating any privacy information on this page. Your continued use of the Thud, Inc. services available through our company’s websites after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. </p>
          <p><b>Contact Information</b></p>
          <p>Thud, Inc. welcomes your questions or comments regarding this statement of privacy. If you believe that Thud has not adhered to this Statement, please contact us at: contact [at] thud [dot] com or by mail to Thud, Inc. at PO Box 49258, Los Angeles, CA 90049.</p>
          <p><b>Last Update</b></p>
          <p>This Privacy Policy was most recently updated on March 1, 2019.</p>
        </div>
      </Grid>
      <Footer />
    </div>
  }
}

export default PrivacyPolicy
