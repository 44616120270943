import denise from '../../assets/images/training/Denise.jpg'
import rocky from '../../assets/images/training/Rocky.jpg'

export const BIOS = {
  WHAT: [
    {
      name: "DENISE ST. IVES",
      image: denise,
      text: "Denise St. Ives is a fully licensed massage therapist who supplements her income by competing in amateur shooting events throughout the state of Florida. She has excelled in singles, couples, and 5-on-5 events, having twice been nominated to the Tallahassee-Area Lady Shooters To Watch list. She joined Team Talonpoint in 2017 to fill the spot left vacant by the passing of longtime member Dorothy “Give ’Em Hell” Tulip at the age of 106.",
    },
    {
      name: "ROCKY ZIMMER",
      image: rocky,
      text: "Based in Houston’s Little Dallas neighborhood, Rocky Zimmer is a former stay-at-home dad and an amateur competitive shooter specializing in the beer can and melon categories. He accepted an offer to join Team Talonpoint in 2016 after winning a write-in contest asking entrants to describe their dream home-invasion defense scenarios.",
    }
  ]
}
