import _ from 'lodash'
import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { addToCheckout } from '/components/Shopify/client'

class OptionValue extends React.Component {
  onClick = (e) => {
    e.preventDefault()
    this.props.onClick(this.props.value.value)
  }

  render() {
    let v = this.props.value.value
    return <button className={Cx({
      [styles.selected]: this.props.selected
    })} onClick={this.onClick}>{v}</button>
  }
}

class Option extends React.Component {
  onPick = (value) => {
    this.props.onPick({
      name: this.props.name,
      value,
    })
  }

  render() {
    let { name, values, selected } = this.props
    return (
      <div key={this.name}>
        <label>Pick {name}:</label><br />{
          values.map((v, i) => <OptionValue
            onClick={this.onPick}
            selected={v.value === selected}
            value={v}
            key={i} />)
        }
      </div>
    )
  }
}

class Options extends React.Component {
  onPick = (pick) => {
    this.props.onPick(pick)
  }

  render() {
    return (
      <div className={styles.options}>
        {
          this.props.options.map(option => {
            return <Option
              {...option}
              selected={this.props.selected[option.name]}
              onPick={this.onPick}
              key={option.name} />
          })
        }
        {this.props.error && <div className={styles.error}>↑ Please pick a size</div>}
      </div>
    )
  }
}

export default class extends React.Component {
  state = {
    selected: {},
    adding: false,
    currentImage: 0,
    error: false,
  }

  shouldShowOptions(product) {
    return product.options[0].values.length > 1
  }

  onPick = (pick) => {
    let selected = _.cloneDeep(this.state.selected)
    selected[pick.name] = pick.value
    this.setState({
      selected,
      error: false
    })
  }

  onAddClick = e => {
    e.preventDefault()
    if (this.state.adding) return
    let variant = this.findVariantFromSelection()
    if (variant) {
      this.setState({ adding: true })
      addToCheckout(variant, 1).then(checkout => this.props.onAdd())
    } else {
      this.setState({ error: true })
    }
  }

  findVariantFromSelection() {
    // If there is only one variant immediately return it
    if (this.props.data.variants.length === 1) {
      return this.props.data.variants[0]
    }
    // Find the variant based on the selections
    let variant = _.find(this.props.data.variants, obj => {
      let options = {}
      _.each(obj.selectedOptions, opt => {
        options[opt.name] = opt.value
      })
      return _.isEqual(options, this.state.selected)
    })
    if (variant) return variant
    return false
  }

  onImageClick = (currentImage) => {
    this.setState({ currentImage })
  }

  onCloseClick = () => {
    this.props.closeModal()
  }

  render() {
    let product = this.props.data
    return <div className={styles.container}>
      <div className={styles.images}>
        <div className={styles.current}>
          <img src={product.images[this.state.currentImage].src} />
        </div>
        <div className={styles.picker}>{
          product.images.map((o, i) => {
            return <a href="#" className={Cx({
              [styles.active]: i === this.state.currentImage
            })} onClick={e => { e.preventDefault() ; this.onImageClick(i) }} key={i}>
              <img src={o.src} />
            </a>
          })
        }</div>
      </div>
      <div className={Cx('type', styles.details)}>
        <h1>{product.title}</h1>
        <div dangerouslySetInnerHTML={{__html: product.descriptionHtml}}/>
        {this.shouldShowOptions(product) &&
          <Options
            options={product.options}
            selected={this.state.selected}
            onPick={this.onPick}
            error={this.state.error} />
        }
        <button className="pill" onClick={this.onAddClick}>
          <span className="h3">{ this.state.adding ? 'Adding...' : 'Add to Cart' }</span>
        </button>
      </div>
      <button className={styles.close} onClick={this.onCloseClick}>
        <svg viewBox="0 0 10 10">
          <line x1="1" y1="1" x2="9" y2="9" />
          <line x1="9" y1="1" x2="1" y2="9" />
        </svg>
      </button>
    </div>
  }
}
