import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import TextColumn from '/components/TextColumn'
import ScrollAnimation from 'react-animate-on-scroll'
import Grid from '/components/Grid'
import { COLORS } from '/constants'

export default class extends React.Component {
  render() {
    return (
      <Grid className={'shadow'}>
        <TextColumn>
          <ScrollAnimation animateIn="pulse" duration={.25} offset={150}>
            <p className="h2 center">ALWAYS READY TO FIRE BECAUSE IT'S ALWAYS FIRING</p>
          </ScrollAnimation>
          <div className="type center">
            <ScrollAnimation animateIn="pulse" duration={.25} offset={200}>
              <p>Talonpoint created TacStorm for one reason: because the second it takes to decide whether or not to shoot could be your last. By constantly discharging rounds from the moment of purchase, this revolutionary new weapon takes the uncertainty out of when to pull a trigger and guarantees you a tactical advantage in any scenario.</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" duration={.25} offset={100}>
              <p className="h2" style={{color: COLORS.GREEN}}><strong>With TacStorm, all you have to do is aim.&trade;</strong></p>
            </ScrollAnimation>
          </div>
        </TextColumn>
      </Grid>
    )
  }
}
