import notrigger from '/assets/images/fourpart/TacStorm_Icons_2-02.png'
import heatresistant from '/assets/images/fourpart/TacStorm_Icons_2-03.png'
import unrivaledrecoil from '/assets/images/fourpart/TacStorm_Icons_2-05.png'
import magazine from '/assets/images/fourpart/TacStorm_Icons_2-04.png'

export const DATA = [
  {
    icon: notrigger,
    title: 'NO TRIGGER',
    text: 'Free up your index finger to do something else',
  },
  {
    icon: magazine,
    title: 'ULTRA HIGH CAPACITY MAGAZINE',
    text: "Just reload once a month and you're good to go",
  },
  {
    icon: unrivaledrecoil,
    title: 'UNRIVALED RECOIL SUPPRESSION',
    text: "Steady shooting no matter how (or whether) you're holding it",
  },
  {
    icon: heatresistant,
    title: 'HEAT RESISTANT BARREL',
    text: 'Tough enough to endure a lifetime of constant fire',
  },
]
