import React from 'react'
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

export default class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      flickityReady: false,
    }

    this.refreshFlickity = this.refreshFlickity.bind(this)
  }

  componentDidMount() {
    this.flickity = new Flickity(this.flickityNode, this.props.options || {})

    this.setState({
      flickityReady: true,
    })
    this.flickity.on('staticClick', (event, pointer, cell, cellIndex) => {
      this.flickity.select(cellIndex)
    })
  }

  refreshFlickity() {
    this.flickity.reloadCells()
    this.flickity.resize()
    this.flickity.updateDraggable()
  }

  componentWillUnmount() {
    this.flickity.destroy()
  }

  componentDidUpdate(prevProps, prevState) {
    const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady
    const childrenDidChange = prevProps.children.length !== this.props.children.length

    if (flickityDidBecomeActive || childrenDidChange) {
      this.refreshFlickity()
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={node => {this.flickityNode = node}}>{this.props.children}</div>
    )
  }
}
