import Cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'
import Img from '/components/Img'
import FixedAspect from '/components/FixedAspect'
import { URLS } from '/constants'
import logo_tacstorm from '/assets/images/tacstorm_logo_text_only.png'
import bullet from '/assets/images/tacstorm_logo_bullet.png'
import VideoBg from './VideoBg'

export default class extends React.Component {
  state = {
    bgReady: false,
    logoReady: false,
    ready: false,
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({  ready: true }), 500)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  onMainImageLoad = () => {
    let state = { bgReady: true }
    if (this.state.logoReady) state.ready = true
    this.setState(state)
  }

  onLogoLoad = () => {
    let state = { logoReady: true }
    if (this.state.bgReady) state.ready = true
    this.setState(state)
  }

  render() {
    return (
      <div className={styles.hero}>
        <VideoBg />
        <div className={styles.heroContent}>
          <div className={Cx(styles.mainlogo, {
            [styles.ready]: this.state.ready
          })}>
            <FixedAspect width={2050} height={291}>
              <Img onLoad={this.onLogoLoad} className={styles.logoText} src={logo_tacstorm} />
              <img className={Cx(styles.bullet, styles.bullet1)} src={bullet} />
              <img className={Cx(styles.bullet, styles.bullet2)} src={bullet} />
              <img className={Cx(styles.bullet, styles.bullet3)} src={bullet} />
              <img className={Cx(styles.bullet, styles.bullet4)} src={bullet} />
              <img className={Cx(styles.bullet, styles.bullet5)} src={bullet} />
            </FixedAspect>
          </div>
          {this.state.ready &&
            <p className={Cx('h2', styles.motto)}>
              <b>THE WORLD'S FIRST CONTINUOUSLY FIRING GUN</b>
            </p>
          }
          {this.state.ready &&
            <div className={styles.watch}>
              <Link to={URLS.DEMO} className={Cx('pill w-notch vibrate', styles.watchDemo)}>
                <p className="h3">Watch the Demo</p>
              </Link>
            </div>
          }
        </div>
      </div>
    )
  }
}
