import Cx from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import logo_tacstorm from '../../assets/images/tacstorm_logo.png'
import logo_talonpoint from '../../assets/images/talonpoint_logo.png'
import styles from './styles'
import { URLS } from '/constants'
import { fetchCheckout, cartEvents } from '/components/Shopify/client'
import CartIcon from './CartIcon'

const LINKS = {
  [URLS.HOME]: 'Intro',
  [URLS.PERMASHOT]: 'Permashot',
  [URLS.SPECS]: 'Specs',
  [URLS.REVIEWS]: 'Reviews',
  [URLS.BUY]: 'Buy',
}

class Navigation extends React.Component {
  state = {
    isOpen: false,
    checkout: false,
  }

  componentDidMount() {
    this.mounted = true
    this.updateCheckout()
    cartEvents.on('checkout.update', this.updateCheckout)
  }

  componentWillUnmount() {
    this.mounted = false
    cartEvents.removeListener('checkout.update', this.updateCheckout)
  }

  updateCheckout = () => {
    fetchCheckout().then(checkout => {
      if (!this.mounted) return
      this.setState({ checkout })
    })
  }

  onMobbleMenuClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.toggle()
  }

  onClickAnywhere = (e) => {
    if (this.state.isOpen) this.toggle()
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    let lineItems = this.state.checkout ? this.state.checkout.lineItems : []
    let total = lineItems.reduce((a, c) => {
      a += c.quantity
      return a
    }, 0)

    return (
      <div className={Cx(styles.container, {
        [styles.open]:this.state.isOpen
      })} onClick={this.onClickAnywhere}>
        <div className={styles.mobileOverlay} />
        <div className={styles.mobileDrawer}>
          {
            Object.keys(LINKS).map(k => <NavLink
              to={k}
              className={styles.header}
              activeClassName={styles.active_link}
              exact
              key={k}>{LINKS[k]}</NavLink>)
          }
        </div>
        <div className={styles.header}>
          <div className={styles.mobileMenu}>
            <button className={styles.mobileMenuButton} onClick={this.onMobbleMenuClick}>
              <svg className={styles.burger} width="24" height="20" viewBox="0 0 24 20">
                <line x1="2" y1="3" x2="22" y2="3" />
                <line x1="2" y1="10" x2="22" y2="10" />
                <line x1="2" y1="17" x2="22" y2="17" />
              </svg>
            </button>
          </div>
          <NavLink className={styles.logolink} to={URLS.HOME}>
            <div className={styles.headerlogo}>
              <img className={styles.talon} src={logo_talonpoint} />
              <span className={styles.logoLine} />
              <img className={styles.tacstorm} src={logo_tacstorm} />
            </div>
          </NavLink>
          <div className={styles.headerlinks}>
            {
              Object.keys(LINKS).map(k => <NavLink
                to={k}
                activeClassName={styles.active_link}
                exact
                key={k}>{LINKS[k]}</NavLink>)
            }
          </div>

          <div className={styles.mobileMenu}>
            <NavLink
              to={URLS.CART}
              className={Cx(styles.cart)}
              activeClassName={styles.active_link}
              exact>
              <CartIcon className={Cx(styles.cartIcon)} />{!!total && <span className={styles.cartTotal}>{total}</span>}
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default Navigation
