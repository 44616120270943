import Cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'
import Grid from '/components/Grid'
import styles from './styles'
import { URLS } from '/constants'
import { fetchProducts } from '/components/Shopify/client'
import Modal from '/components/Modal'
import Product from './Product'
import { isIe } from '/helpers'

const IS_IE = isIe()

class ProductPreview extends React.Component {
  onClick = (e) => {
    e.preventDefault()
    if (this.props.onProductClick) {
      this.props.onProductClick(this.props.data)
    }
  }

  render() {
    let delay = window.innerWidth < 600 ? 0 : 100
    return (
      <div className={Cx(styles.product, "type center gutter")} onClick={this.onClick}>
        <ScrollAnimation animateIn="pulse" delay={this.props.index * delay} duration={.25} offset={400}>
          <img className={Cx(styles.productImage)} src={this.props.data.images[0].src} />
        </ScrollAnimation>
        <button className={Cx('pill w-notch')}><p className="h3">Buy</p></button>
      </div>
    )
  }
}

class Gear extends React.Component {
  state = {
    current: false,
    products: [
      false,
      false,
      false
    ]
  }

  componentDidMount() {
    this.mounted = true
    this.productsPromise = fetchProducts().then(products => {
      if (!this.mounted) return
      this.setState({ products })
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onProductClick = (productData) => {
    this.setState({ current: productData })
  }

  closeModal = () => {
    this.setState({ current: false })
  }

  goToCart = () => {
    this.props.history.push(URLS.CART)
  }

  render() {
    if (IS_IE) {
      return <Grid className="shadow">
        <div className="type center">
          <p className="h2">GEAR</p>
        </div>
        <div className="center">Sorry, to buy Tacstorm gear you must be on a modern browser.</div>
      </Grid>
    }
    return (
      <Grid className="shadow">
        <div className="type center">
          <p className="h2">GEAR</p>
        </div>
        <div className={styles.gear}>{ this.state.products.map((o, i) => {
          if (o === false) {
            return (
              <div className={Cx(styles.product, "type center gutter")} key={i}>Loading...</div>
            )
          }
          return <ProductPreview onProductClick={this.onProductClick} index={i} data={o} key={i} />
        })}</div>
        {!!this.state.current &&
          <Modal onOutsideClick={this.closeModal}>
            <Product
              onAdd={this.goToCart}
              closeModal={this.closeModal}
              data={this.state.current} />
          </Modal>
        }
      </Grid>
    )
  }
}

export default withRouter(Gear)
