import { TweenLite } from 'gsap'
const SCROLL_POS = {
  x: 0,
  y: 0
}

export function scrollTo(el, offset=0, duration=0.75) {
  let to = el.getBoundingClientRect().top + window.pageYOffset + offset
  // Clean Up Previous Scrolls
  TweenLite.killTweensOf(SCROLL_POS)
  SCROLL_POS.y = window.pageYOffset
  // Animate Scroll
  TweenLite.to(SCROLL_POS, duration, {
    y: to,
    ease: Quart.easeOut,
    onUpdate: () => {
      window.scrollTo(SCROLL_POS.x, SCROLL_POS.y)
    }
  })
}

export function getContainDimensions(aspect, win_w, win_h) {
  let width
  let height
  let left
  let top
  let win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_w
    height = win_w / aspect
    top = (win_h - height) * 0.5
    left = 0
  } else {
    width = win_h * aspect
    height = win_h
    left = (win_w - width) * 0.5
    top = 0
  }
  return {width, height, left, top}
}

export function getCoverDimensions(aspect, win_w, win_h) {
  let width
  let height
  let left
  let top
  let win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_h * aspect
    height = win_h
    left = (win_w - width) * 0.5
    top = 0
  } else {
    width = win_w
    height = win_w / aspect
    top = (win_h - height) * 0.5
    left = 0
  }
  return { width, height, left, top }
}

// IE version
export function isIe() {
  let isIeTest = false
  let ua = navigator.userAgent
  let re
  if (navigator.appName == 'Microsoft Internet Explorer') {
    re  = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})')
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 )
  } else if (navigator.appName == 'Netscape') {
    re  = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})')
    if (re.exec(ua) !== null)
      isIeTest = parseFloat( RegExp.$1 )
  }
  return isIeTest
}