import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from '../Home'
import Navigation from '../../components/Navigation'
import Training from '../Training'
import CustomerService from '../CustomerService'
import PrivacyPolicy from '../PrivacyPolicy'
import { URLS } from '../../constants'
import { scrollTo } from '../../helpers'
import VideoPlayer from '/views/VideoPlayer'

class WrappedRoot extends React.Component {
  componentDidMount() {
    this.scrollToSlug(this.props.match.params.slug)
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      if (!this.props.location.state || !this.props.location.state.noscroll) {
        this.scrollToSlug(this.props.match.params.slug)
      }
    }
  }

  scrollToSlug(slug) {
    if (!slug) slug = ''
    // Check if document.elementGetById(slug) exists
    let el = document.getElementById('/' + slug)
    // If so use scrollTo Helper to get there
    // TODO REPLACE THE MAGIC NUMBER BELOW WITH THE MENU HEIGHT
    // Replaced magic number for desktop but not for mobile / tablet - Natalie
    if (el) {
      scrollTo(el, -65)
    } else {
      // Go to top of the window
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <div>
      <Navigation />
      <Switch>
        <Route path={URLS.TRAINING} component={Training} />
        <Route path={URLS.CUSTOMER_SERVICE} component={CustomerService}/>
        <Route path={URLS.PRIVACYPOLICY} component={PrivacyPolicy} />
        <Route path={URLS.HOME} component={Home} />
        <Redirect to="/" />
      </Switch>
      <Route path={URLS.DEMO} exat component={VideoPlayer} />
    </div>
  }
}

class Root extends React.Component {
  render() {
    return <Route path={'/:slug?'} children={(props) => <WrappedRoot {...props} />} />
  }
}

export default Root
