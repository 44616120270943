import React from 'react'
import { render } from 'react-router-dom'
import Slider from '/components/Slider'
import styles from './styles'
import { SOCIAL } from './data'

class Testimonials extends React.Component {
  render() {
    return (
      <Slider
        className={styles.slider}
        options={{
          autoPlay: 4000,
          pauseAutoPlayOnHover: true,
          wrapAround: true,
          fullscreen: true,
          adaptiveHeight: true,
        }}
      >
        { SOCIAL.WHAT.map((o, i) => {
          return (
            <div className={styles.testimonialholder} key={i}>
              <div className={styles.testimonials}>
                <img className={styles.testimonialImage} src={o.image} />
                <div><p><b>"{o.content}"</b></p></div>
                <div><p><i>- {o.handle}</i></p></div>
              </div>
            </div>
          )
        })}
      </Slider>
    )
  }
}

export default Testimonials
