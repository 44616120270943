import React from 'react'
import Cx from 'classnames'
import { render } from 'react-router-dom'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'
import styles from './styles'
import grocery from '../../assets/images/mediafiles/TacStorm_GroceryStore.jpg'
import party from '../../assets/images/mediafiles/TacStorm_GroupShot.jpg'
import police from '../../assets/images/mediafiles/TacStorm_PoliceOfficer.jpg'
import gun from '../../assets/images/mediafiles/TacStorm_BehindGun.jpg'

const photos = [
  {
    src: grocery,
    width: 2.5,
    height: 1.5
  },
  { src: police,
    width: 1.5,
    height: 1.5
  },
  {
    src: gun,
    width: 2.2,
    height: 1.5
  },
  { src: party,
    width: 1.8,
    height: 1.5
  },
]

class ImageGallery extends React.Component {
  constructor() {
    super()
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  render() {
    return (
      <div className={Cx(this.props.className, styles.imgs)}>
        <Gallery 
          photos={photos}
          columns={window.innerWidth < 500 ? 1 : 2}
          onClick={this.openLightbox}
        />
        <Lightbox images={photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          backdropClosesModal={true}
        />
      </div>
    )
  }
}

export default ImageGallery
