import Cx from 'classnames'
import React from 'react'
import styles from './styles'
import HeroVideo from '/assets/Hero_1080.mp4'
import { getCoverDimensions } from '/helpers'

export default class extends React.Component {
  state = {
    width: 1280,
    height: 720,
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    let aspect = 2144 / 1080
    let rect = this._el.getBoundingClientRect()
    let dimens = getCoverDimensions(aspect, rect.width, rect.height)
    this.setState({
      width: dimens.width,
      height: dimens.height,
    })
  }

  render() {
    return (
      <div className={styles.container} ref={c=>{this._el=c}}>
        <video
          width={this.state.width + 'px'}
          height={this.state.height + 'px'}
          autoPlay
          loop
          className={styles.video}
          muted
          playsInline
          ref={c=>{this._video=c}}>
          <source
            src={HeroVideo}
            type="video/mp4"/>
        </video>
      </div>
    )
  }
}
