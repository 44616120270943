import React from 'react'
import Cx from 'classnames'
import { render } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
import { SPECLIST, technicaldata, construction, features, additional } from './data'
import styles from './styles'
import specsmobile from '../../assets/images/specs/TACSTORM-Mobile.png'

const HeatShieldTarget = (props) => {
  return (
    <svg viewBox="0 0 52 116">
      <line x1="25" y1="117" x2="26" y2="51"/>
      <circle cx="26" cy="26" r="25"/>
    </svg>
  )
}

const SunroofTarget = (props) => {
  return (
    <svg viewBox="0 0 88 60">
      <circle cx="25.4" cy="34.8" r="24.4"/>
      <polyline points="87.6,1.3 62.7,1.3 43.6,18.4 "/>
    </svg>
  )
}

const SafetyTarget = (props) => {
  return (
    <svg viewBox="0 0 86 52">
      <circle cx="26" cy="26" r="25"/>
      <line x1="51" y1="26" x2="86" y2="26"/>
    </svg>
  )
}

const EngravingsTarget = (props) => {
  return (
    <svg viewBox="0 0 72 65">
      <circle cx="26" cy="26" r="25"/>
      <line x1="45" y1="42" x2="71" y2="64"/>
    </svg>
  )
}

const LubricantTarget = (props) => {
  return (
    <svg viewBox="0 0 80 52">
      <line x1="80" y1="26" x2="51" y2="26"/>
      <circle cx="26" cy="26" r="25"/>
    </svg>
  )
}

const KeychainTarget = (props) => {
  return (
    <svg viewBox="0 0 80 52">
	    <line x1="0" y1="26" x2="29" y2="26"/>
	    <circle cx="54" cy="26" r="25"/>
    </svg>
  )
}


class Specs extends React.Component {
  render() {
    return (
      <div>
        <ScrollAnimation animateIn="pulse" duration={.25}>
          <div className={styles.profilespecs}>
            <div className={Cx(styles.spec, styles.specHeat)}>
              <HeatShieldTarget />
              <div>NASA-Grade Heat Shield</div>
            </div>
            <div className={Cx(styles.spec, styles.specSunroof)}>
              <div>Sunroof</div>
              <SunroofTarget />
            </div>
            <div className={Cx(styles.spec, styles.specSafety)}>
              <SafetyTarget />
              <div>Ornamental Safety</div>
            </div>
            <div className={Cx(styles.spec, styles.specEngravings)}>
              <EngravingsTarget />
              <div>Space For Up To Four Eagle Engravings</div>
            </div>
          </div>
        </ScrollAnimation>
        <div className={styles.bottomspecs}>
          <ScrollAnimation className={styles.backspecsWrapper} animateIn="pulse" duration={.5}>
            <div className={styles.backspecs}>
              <div className={Cx(styles.spec, styles.specLubricant)}>
                <LubricantTarget />
                <div>Firearm Lubricant Sprinkler</div>
              </div>
              <div className={Cx(styles.spec, styles.specKeychain)}>
                <div>Keychain Mount</div>
                <KeychainTarget />
              </div>
            </div>
          </ScrollAnimation>
          <div className={styles.speclist}>
            <ScrollAnimation animateIn={styles.specsStepIn} duration={.25}>
              <ul>{
                SPECLIST.specs.map((specs,i) => <li
                  style={{ animationDelay: (i * 50) + 'ms'}}
                  dangerouslySetInnerHTML={{__html: (specs)}}
                  key={i} />)
              }</ul>
            </ScrollAnimation>
          </div>
        </div>
        <div className={styles.mobilelayout}>
          <ScrollAnimation animateIn="pulse" duration={.25}>
            <img className={styles.specsimage} src={specsmobile}/>
          </ScrollAnimation>
          <div className={styles.speclist}>
            <ScrollAnimation animateIn="pulse" duration={.25}>
              <div className={styles.specheader} dangerouslySetInnerHTML={{__html: technicaldata.title}}/>
              <ul className={styles.speclist}>
                {technicaldata.info.map((info,i) => <li dangerouslySetInnerHTML={{__html: (info)}} key={i} />)
                }
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" duration={.25}>
              <div className={styles.specheader} dangerouslySetInnerHTML={{__html: construction.title}}/>
              <ul className={styles.speclist}>
                {construction.info.map((info,i) => <li dangerouslySetInnerHTML={{__html: (info)}} key={i} />)
                }
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" duration={.25}>
              <div className={styles.specheader} dangerouslySetInnerHTML={{__html: features.title}}/>
              <ul className={styles.speclist}>
                {features.info.map((info,i) => <li dangerouslySetInnerHTML={{__html: (info)}} key={i} />)
                }
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" duration={.25}>
              <div className={styles.specheader} dangerouslySetInnerHTML={{__html: additional.title}}/>
              <ul className={styles.speclist}>
                {additional.info.map((info,i) => <li dangerouslySetInnerHTML={{__html: (info)}} key={i} />)
                }
              </ul>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    )
  }
}

export default Specs
