import Cx from 'classnames'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styles from './styles'
import { COLORS } from '/constants'
import Grid from '/components/Grid'
import SectionTitle from '/components/SectionTitle'
import TextColumn from '/components/TextColumn'
import { DATA } from './data'

export default class extends React.Component {
  render() {

    let delay = (window.innerWidth < 600) ? 0 : 150

    return (
      <Grid className={styles.pattern}>
        <SectionTitle>FOUR-PART PERMASHOT TECHNOLOGY</SectionTitle>
        <TextColumn className="center">
          <p>Every TacStorm is built right here in the U.S.A. and utilizes our patented Permashot technology.</p>
        </TextColumn>
        <div className={styles.permashoticons}>{ DATA.map((o, i) => {
          return (
            <ScrollAnimation
              animateIn="pulse"
              delay={delay * i}
              duration={.4}
              offset={400}
              animateOnce
              key={i}>
              <div className={styles.tech} key={i}>
                <img className={Cx(styles.imageicon)} src={o.icon} />
                <div className={Cx('h3', styles.icontitle)}>{o.title}</div>
                <div className={styles.icontext}>{o.text}</div>
              </div>
            </ScrollAnimation>
          )
        })}</div>
        <ScrollAnimation
          animateIn="pulse"
          duration={.25}
          offset={250}
          animateOnce>
          <div className="h3 center" style={{color: COLORS.GREEN}}><strong>...and much more</strong></div>
        </ScrollAnimation>
      </Grid>
    )
  }
}
