import React from 'react'
import Cx from 'classnames'
import styles from './styles'

class FixedAspect extends React.Component {
  static defaultProps = { orientation: 'h' }
  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const rect = this._el.parentElement.getBoundingClientRect()
    if (this.props.orientation === 'h') {
      this._el.style.width = '100%'
      this._el.style.height = rect.width * this.props.height / this.props.width + 'px'
    } else {
      this._el.style.width = rect.height * this.props.width / this.props.height + 'px'
      this._el.style.height = '100%'
    }
  }

  render() {
    let { className, width, height, orientation, ...rest } = this.props

    return (
      <div
        className={Cx(styles.container, className)}
        ref={c => { this._el = c }}
        {...rest}>{this.props.children}</div>
    )
  }
}

export default FixedAspect