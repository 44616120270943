export const SPECLIST = {
  specs: [
    '<b>LENGTH</b>: 13.68” (Perceived); 10.12” (Actual)',
    '<b>RATE OF FIRE</b>: 122,400 rounds/day',
    '<b>SHOOTING MODE</b>: Auto-automatic',
    '<b>COLOR</b>: Typical-gun black',
    '<b>HOLSTERABILITY</b>: 26',
    '<b>HEFT</b>: Respectable',
    '<b>FIRING SOUND</b>: *plewnk*',
    '<b>MUZZLE FLASH SHAPE</b>: Angry starfish',
    '<b>FINISH</b>: Able to withstand years of caressing',
    '<b>PERMITTED</b>: Every U.S. state except–you guessed it–California',
    '<b>MOMSGUIDE.COM USER RATING</b>: 4.6/5',
    '<b><i>Optional Accessories</b>: Soundproof carrying case; Noise-canceling sleep mask; Shell casing collection sack (25 gal / 50 gal)</i>',
  ]
}

export const technicaldata = {
  title: 'Technical Data',
  info: [
    '<b>LENGTH</b>: 13.68” (Perceived); 10.12” (Actual)',
    '<b>RATE OF FIRE</b>: 122,400 rounds/day',
    '<b>SHOOTING MODE</b>: Auto-automatic',
    '<b>HOLSTERABILITY</b>: 26',
    '<b>FIRING SOUND</b>: *plewnk*',
  ]
}

export const construction = {
  title: 'Construction',
  info: [
    '<b>COLOR</b>: Typical-gun black',
    '<b>HEFT</b>: Respectable',
    '<b>FINISH</b>: Able to withstand years of caressing',
    '<b>MUZZLE FLASH SHAPE</b>: Angry starfish',
  ]
}

export const features = {
  title: 'Features',
  info: [
    'NASA-GRADE HEAT SHIELD',
    'ORNAMENTAL SAFETY',
    'FIREARM LUBRICANT SPRINKLER',
    'KEYCHAIN MOUNT',
    'SUNROOF',
    'SPACE FOR UP TO FOUR EAGLE ENGRAVINGS',
  ]
}

export const additional = {
  title: 'Additional Information',
  info: [
    '<b>PERMITTED</b>: Every U.S. state except–you guessed it–California',
    '<b>MOMSGUIDE.COM USER RATING</b>: 4.6/5',
    '<b><i>Optional Accessories</b>: Soundproof carrying case; Noise-canceling sleep mask; Shell casing collection sack (25 gal / 50 gal)</i>',
  ]
}
