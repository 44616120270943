import Cx from 'classnames'
import React from 'react'
import styles from './styles'

export default ({ className, children, ...rest}) => {
  return (
    <div className={Cx(styles.section, className)} {...rest}>
      <div className={Cx(styles.content)}>{ children }</div>
    </div>
  )
}